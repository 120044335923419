@media only screen and (min-width: 576px) {
  .navbar .nav-container {
    max-width: 564px;
  }
  .ms-auto{
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .navbar .nav-container {
    max-width: 744px;
  }
  .ms-auto{
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 992px) {
  .navbar .nav-container {
    max-width: 57.5rem;
  }
  .ms-auto{
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 576px) {
  .main-div .grow-container {
    max-width: 540px;
  }
  .ms-auto{
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .main-div .grow-container {
    max-width: 720px;
  }
  .ms-auto{
    margin-left: 0 !important;
  }
}

@media only screen and (min-width: 992px) {
  .main-div .grow-container {
    max-width: 56rem;
  }
  .navbar-nav{
    flex-direction: row !important;
  }
  .ms-auto{
    margin-left: 0 !important;
  }
}

.main-div {
  margin-top: 2rem;
}

.main-logo {
  height: 2.2rem;
  padding-left: 0.75rem;
}

:root {
  --primary-color: #f38800;
  --secondary-color: #780121;
  --panel-bg-color: #ffffff;
  --card-bg-color: #eaeaea;
  --text-color: #070404;
  --text-color-white :#ffffff;
  --button-text: var(--text-color-white);
  --text-color-on-dark-bg: black;
  --label-text: black;
  --login-labels: black;
  --success-color: #739b24;
  --loading-bg-color: rgba(172, 172, 172, 0.829);
  --main-gradient: linear-gradient(0deg, #0000008a 0%, transparent 70%);
  --button-box-shadow: #ffe03c;
  --input-border-color: #ced1d4;
  --input-border-focus: #f38800;
  --input-focus-shadow: 0 1px 1px rgba(229, 103, 23, 0.075) inset,
    0 0 8px #f38800;
  --btn-secondary-bg: #EAEAEA;
  --btn-secondary-hover: #5c0018;
  --btn-secondary-text: white;

  --nav-bg-color: var(--secondary-color);
  --icon-color: var(--primary-color);
  --button-color: var(--secondary-color);
  --button-text: var(--primary-color);
  --nav-text-color: var(--primary-color);
  --button-color: var(--secondary-color);
  --button-text: var(--primary-color);
  --completed-tile-color: var(--success-color);

  --main-font: "Roboto", sans-serif;
  --secondary-font: "Roboto", sans-serif;
}

/* :root {
 --primary-color: #ff9500;
  --secondary-color: #640215;
  --panel-bg-color: #000000;
  --card-bg-color: #4e4e4e;
  --text-color: #000000;
  --text-color-on-dark-bg: white;
  --success-color: green;
  --loading-bg-color: rgba(172, 172, 172, 0.829);

  --nav-bg-color: var(--secondary-color);
  --icon-color: var(--primary-color);
  --button-color: var(--secondary-color);
  --button-text: var(--primary-color);
  --nav-text-color: var(--primary-color);
  --button-color: var(--secondary-color);
  --button-text: var(--primary-color);
}   */
.nav-style {
  background: var(--nav-bg-color);
}

nav .nav-link,
.navbar-toggler {
  color: var(--nav-text-color);
}
div.navbar-collapse{
  justify-content: flex-end;
  display: flex;
}
div.navbar-expand-md.navbar-collapse{
  justify-content: flex-end;
}
ul.navbar-nav{
  flex-direction: row;
}
.navbar-nav > li{
  padding: 5px;
}
.navbar-nav .nav-link:focus,
.navbar-nav .nav-link:hover {
  color: var(--primary-color);
  box-shadow: none;
}

.header-text {
  color: var(--text-color-white);
}

.main-div {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.card {
  background: var(--card-bg-color);
}

.link-unstyled {
  color: inherit;
  text-decoration: none;
}

.link-unstyled:hover {
  color: inherit;
  text-decoration: none;
}

.text-color {
  color: var(--text-color);
}

h1 {
  color: var(--icon-color);
}

.form {
  max-width: 56rem;
}

.form .form-control {
  background: var(--card-bg-color);
}

label {
  color: var(--label-text);
}

#tiles-container,
.button-style,
.login-button,
.navbar {
  font-family: var(--secondary-font);
}

textarea.form-control:focus,
input:focus,
input[type]:focus,
.uneditable-input:focus {
  border-color: var(--input-border-focus);
  box-shadow: var(--input-focus-shadow);
  outline: 0 none;
}

@media only screen and (max-width: 768px) {
  .dots-icon {
    width: 4.4rem;
  }
}

@media only screen and (min-width: 768px) {
  .navbar-brand {
    width: 7rem;
  }
}

@media only screen and (min-width: 768px) {
  .navbar-collapse {
    width: 7rem;
  }
}

/* Toggle switch start */
.form-check-input {
  width: 60px;
  height: 28px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* toggle switch end */

/* Buttons zuruck and weiter start */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: var(--panel-bg-color);
}

label.button-style,
a.button-style,
button.button-style {
  color: var(--text-color-white) !important;
  background: var(--button-color) !important;
  min-width: 10rem;
}

label.button-style:hover,
a.button-style:hover,
button.button-style:hover {
  color: var(--text-color) !important;
  background-color:var(--text-color-white) !important;
  border-color: var(--primary-color);
}

label.button-style:active,
a.button-style:active,
button.button-style:active,
label.button-style:focus,
a.button-style:focus,
button.button-style:focus {
  color: var(--text-color) !important;
  background-color:var(--text-color-white) !important;
  box-shadow: 0 0 0 0.2rem var(--button-box-shadow);
}

.completed-tile {
  color: var(--completed-tile-color);
}

.spinner-wrapper .spinner-border {
  width: 3rem;
  height: 3rem;
  color: var(--secondary-color);
}

.spinner-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--loading-bg-color);
  z-index: 100;
}

.default-image {
  color: var(--card-bg-color);
  font-size: 10rem;
}

@media only screen and (min-width: 992px) {
  .button-style {
    max-width: 25rem;
  }
}
/* Buttons zuruck and weiter end */

/* Dropdown start*/
.dropdown .secondary-button {
  color: var(--text-color);
  background: var(--card-bg-color);
  border-color: var(--input-border-color);
}

.dropdown .secondary-button:active,
.dropdown .secondary-button:focus {
  color: var(--text-color);
  background-color: var(--panel-bg-color);
  border-color: var(--input-border-focus);
  box-shadow: var(--input-focus-shadow);
  outline: 0 none;
}

.dropdown .secondary-button {
  width: 100%;
}

.btn .language-span {
  display: inline-block;
  width: 100%;
}

.dropdown .dropdown-toggle::after {
  margin-left: -0.75rem;
}

.dropdown-menu > li:active > a {
  background-color: #ff950093;
}
/* dropdown end */

/* toast start*/
#toast-message .toast-container {
  color: var(--panel-bg-color);
}
/* toast end*/

.scroll-div {
  margin-bottom: 8rem;
}

.form-check .form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--secondary-color);
}

button.button-secondary {
  background: var(--btn-secondary-bg);
  transition: all 0.2s linear;
  color: var(--secondary-color);
  border-color: var(--secondary-color);
}

button.button-secondary:hover {
  color: var(--btn-secondary-text);
  background: var(--btn-secondary-hover);
  border-color: var(--secondary-color);
}

button.button-secondary:focus,
button.button-secondary:active {
  outline: none;
  box-shadow: none;
}

.nav-item .setting-button{
  padding: 0.25rem 0.95rem !important;
  color: #949494 !important;
  border: #949494 0.1em solid !important;
}
.nav-item .setting-button:hover,
.nav-item .setting-button:focus{
  color: var(--text-color-white) !important;
  background-color: #292929;
  border: 0.1em solid #f8fc00 !important;
}


.navbar-nav{
  column-gap: 0.5rem !important;
}

@media screen and (min-width: 1200px) and (max-width : 1920px){
  .fs-calc{
      font-size: 1.1rem !important;
  }
  .setting-button{
    font-size: 1.1rem !important;
  }
}
.navbar .nav-container{
  align-items: baseline !important;
}
@media screen and (min-width: 368px) and (max-width : 768px){
  .fs-calc{
      font-size:0.93rem !important;
  }
  .setting-button{
    font-size: 1rem !important;
  }
}
@media screen and (min-width: 768px) and (max-width : 1200px){
  .fs-calc{
      font-size: 0.98rem !important;
  }
  .setting-button{
    font-size: 1rem !important;
  }
}
@media screen and (min-width: 200px) and (max-width : 368px){
  .fs-calc{
      font-size:0.85rem !important;
  }
  .setting-button{
    font-size: 0.85rem !important;
  }
}
@media only screen and (min-width: 768px) {
  .dots-icon {
    width: 0.5rem;
  }
}