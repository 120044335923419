
.login-background {
  background-image: linear-gradient(#27272754, #27272754), url(/src/images/background.jpg) !important;
  background-position: center;
  background-repeat: repeat; 
  background-size: cover;
  height: 100vh;
}

h2.text-uppercase{
  font-family: 'Roboto', sans-serif;
}
.mb-3 > .invalid{
  border: 2px solid #e04040bd;
  border-radius: 5px;
  background-color: #fdcaca;
}
.mb-3 > .error{
  margin-top:15px;
  background-color: #fdcaca;
  border: 3px solid #e04040bd;
  border-radius: 5px;
}
button.login-button {
  color: var(--text-color-white);
  background: var(--button-color);
  background-image: var(--main-gradient);
  min-width: 10rem;
  margin: 0px;
  box-sizing: border-box;
  border: 1px 1px solid #949494;
  cursor: pointer;
  padding: 2px 15px 2px 15px;
  background: transparent;
  text-align: center;
  text-decoration: none;
  color: #949494;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  width: 30%!important;
  float: right;
  transition: all ease-in-out 0.5s;
}

button.login-button:hover {
  background-color: #fff;
  border-color: var(--primary-color);
  color: #fff !important;
  background-color: #350015;
  border-color: #f38800;
}

button.login-button:active,
button.login-button:focus {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff !important;
  background-color: #350015;
  border-color: #f38800;
  box-shadow: 0 0 0 0.2rem var(--button-box-shadow);
}

.login-form {
  border: 2px solid var(--primary-color);
  left: 40vw;
  padding: 25px;
  position: relative;
  top: 10vw;
  width: 30rem!important;
  transition: all ease-in-out 0.5s;
}

.login-icon{
  display: flex;
  height: 35px;
  width: 35px;
  margin-right: 5px;
  fill: var(--primary-color);
  float:left;
  transition: all ease-in-out 0.5s;
}

.form-control{
  border-radius: 0;
  border: 0;
  transition: all ease-in-out 0.5s;
}

.login-form .row{
  margin: 0px !important;
  background: var(--panel-bg-color);
}

.login-form .row .col{
  padding: 30px;
}

.login-background label {
  color: var(--login-labels);
}

@media only screen and (min-width:280px) and (max-width: 348px){
  button.login-button{
    font-size: 0.6rem;
    padding:0;
    top: 85%;
    width: 100% !important;
    left: 0;
  }
  .login-form{
    border: 2px solid var(--primary-color);
    left:0;
    padding: 10px;
    position: relative;
    top: 20%;
    width: 100% !important;
  }
  .login-icon{
    height: 25px;
    width: 25px;
  }
  .form-label{
    font-size: 0.8rem;
  }
  .form-check-label{
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 348px) and (max-width: 586px){
  button.login-button{
    width: 24%!important;
    font-size: 0.6rem;
    padding: 0;
    top: 85%;
    width: 100% !important;
    left: 0;
  }
  .login-form{
    padding: 5px;
    position: relative;
    top: 30%;
    left: 0;
    width: 60%!important;
    margin: 0 auto;
  }
  .login-icon{
    height: 30px;
    width: 30px;
  }
  .form-label{
    font-size: 0.8rem;
  }
  .form-check-label{
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 586px) and (max-width: 798px){
  button.login-button{
    width: 24%!important;
    font-size: 0.6rem;
    left: calc(586px - 345px);
  }
  .login-form{
    left: 2rem;
    margin: 0 auto;
    padding: 15px;
    position: relative;
    top: 25vh;
    width: 20rem!important;
  }
  .login-icon{
    height: 30px;
    width: 30px;
  }
  .form-label{
    font-size: 0.8rem;
  }
  .form-check-label{
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 798px) and (max-width:1890px){
  button.login-button{
    width: 28% !important;
    font-size: 16px;
    left: calc(100% - 10rem);
  }
  .login-form{
    top: calc(100vh - 75vh);
  }
  .login-icon{
    height: 35px;
    width: 35px;
  }
  .form-label{
    font-size: 1rem;
  }
  .form-check-label{
    font-size: 1rem;
  }
}