.image-fluid {
  width: 100%;
}

.scroll-div {
  margin-bottom: 8rem;
}

.close-wrap {
  width: 1.4rem;
  height: 1.4rem;
  background-color: rgba(0, 0, 0, 0.719);
  border-radius: 50%;
  border: 2px solid rgba(160, 160, 160, 0.884); 
}

.close {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  font-size: 0.6rem;
}

.light-switch {
  opacity: 0.5;
}

@media (min-width: 768px) {
  .col .w-md-50 {
    width: 50% !important;
  }
}