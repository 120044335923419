.flex-form{
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-flow: column;
    width: 100%;
    height: 100%;
    border: 3px solid black;
    border-bottom : none;
    border-top : none;
}
.flex-fields{
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-flow: row;
    gap: 25px;
    border-bottom: 3px solid #adb5bd;
    margin: 15px;
    align-items: center;
}
.flex-fields > label{
    width: 40%;
    padding: 5px 0 0 5px;
    align-self: baseline;
}
.flex-fields > p{
    width: 50%;
    padding: 5px 0 0 5px;
    align-self: baseline;
}
.flex-checkBox{
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    flex-flow: row;
    gap: 25px;
    align-items: baseline;
}
.hidden{
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
}
.onCheckDiff{
    padding-bottom:15px
}
.onCheckDiff > .checkBoxDiff,
.onCheckDiff > .labelDiff{
    max-width: fit-content;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
}