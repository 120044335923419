#tiles-container .card {
    background: var(--card-bg-color);
}

@media only screen and (max-width: 576px) {
    .card-body .card-text{
        font-size: 14px !important;
    }
}

.card-body .card-text {
    font-size: 16px;
}