html {
  height: 100%;
}

body {
  background: var(--panel-bg-color) !important; 
  font-family: var(--main-font) !important;
  background-repeat: repeat; 
  background-position: center;
  background-size: cover;
}